import {
    AiFillInstagram as InstagramIcon,
    AiOutlineTwitter as TwitterICon,
} from "react-icons/ai";
import { FaFacebookSquare as FacebookIcon } from "react-icons/fa";
import { ImLinkedin as LinkedinIcon } from "react-icons/im";
import { MdMail as MailIcon } from "react-icons/md";

export const SOCIAL_LINKS = [
    {
        label: "Linkedin",
        href: "https://www.linkedin.com/company/the-bithub/",
        icon: <LinkedinIcon />,
    },
    {
        label: "Facebook",
        href: "https://www.facebook.com/the.bithub",
        icon: <FacebookIcon />,
    },
    {
        label: "Instagram",
        href: "https://instagram.com/the.bithub?igshid=YmMyMTA2M2Y=",
        icon: <InstagramIcon />,
    },
    {
        label: "Twitter",
        href: "https://twitter.com/the_bithub",
        icon: <TwitterICon />,
    },
    {
        label: "E-mail",
        href: "mailto:contact-us@the-bithub.com",
        icon: <MailIcon />,
    },
];
