import { Box, Flex, IconButton, useDisclosure } from "@chakra-ui/react";
import { AnimatePresence, motion } from "framer-motion";
import MaxWidthWrapper from "../MaxWidthWrapper/MaxWidthWrapper";
import { useEffect, useState } from "react";
import { IoClose as CloseIcon } from "react-icons/io5";

interface AnnouncementProps {
    children: React.ReactNode;
    isOpened?: boolean;
}

let announcementIsClosedByUser = false;

function Announcement(props: AnnouncementProps) {
    const [isOpened, setIsOpened] = useState(announcementIsClosedByUser ? false : props.isOpened)
    
    const handleClose = () => {
        setIsOpened(false);
        announcementIsClosedByUser = true;
    }

    return (
        <AnimatePresence initial={false}>
            {isOpened ? (
                <Box
                    as={motion.div}
                    textAlign="center"
                    bgGradient="linear(to-r, brand.800, brand.500)"
                    color="white"
                    fontSize={{ base: "xs", md: "sm" }}
                    initial={{ height: 0 }}
                    animate={{ height: "auto" }}
                    exit={{ height: 0 }}
                >
                    <MaxWidthWrapper py={3} as={Flex} alignItems="center">
                        <Box mx="auto">
                            {props.children}
                        </Box>

                        <IconButton
                            aria-label="close announcement"
                            variant="ghost"
                            size="xs"
                            fontSize="md"
                            colorScheme="whiteAlpha"
                            icon={<CloseIcon />}
                            onClick={handleClose}
                        />
                    </MaxWidthWrapper>
                </Box>
            ) : null}
        </AnimatePresence>
    );
}

export default Announcement;