export const QUICK_LINKS = [
    {
        href: "/",
        label: "Home",
    },
    {
        href: "/pricing",
        label: "Pricing",
    },
    {
        href: "/profile",
        label: "Account",
    },
];

export const COMPANY_LINKS = [
    {
        href: "/about",
        label: "About Us",
    },
    // {
    //     href: "/contact",
    //     label: "Contact Us",
    // },
    {
        href: "/privacy",
        label: "Privacy Policy",
    },
    {
        href: "/terms-of-service",
        label: "Terms of Service",
    },
    {
        href: "/refund-policy",
        label: "Refund Policy",
    },
];

export const HELP_LINKS = [
    {
        href: "/faqs",
        label: "FAQ",
    },
    // {
    //     href: "/contact",
    //     label: "Contact Us",
    // }
];
