import React, { useEffect } from "react";
import {
    Box,
    Button,
    Flex,
    HStack,
    IconButton,
    useDisclosure,
    useOutsideClick,
    useEventListener,
    Portal,
    Menu,
    MenuButton,
    Avatar,
    MenuList,
    MenuItem,
} from "@chakra-ui/react";
import MaxWidthWrapper from "../MaxWidthWrapper";
import Logo from "@features/ui/Logo";
import {
    AUTH_LINKS,
    HEADER_HEIGHT,
    NAV_LINKS,
} from "./StandardLayout.constants";
import Link from "next/link";
import { HiOutlineMenuAlt3 as MenuIcon } from "react-icons/hi";
import { FiSettings as ProfileSettings } from "react-icons/fi";
import { useRouter } from "next/router";
import { useAuth } from "@features/users/hooks";
import { AiOutlineLogout as LogoutIcon } from "react-icons/ai";
import auth from "@features/users/auth";
import { CgProfile as ProfileIcon } from "react-icons/cg";
import FeedbackButton from "@features/users/components/FeedbackButton";
import { isUserUsingFreePlan } from "@features/users/helpers";
import Announcement from "../Announcement/Announcement";

function DesktopNavigation() {
    const { pathname } = useRouter();
    const { user } = useAuth();

    return (
        <Flex
            align="center"
            justify="space-between"
            as="nav"
            flexGrow="1"
            display={{ base: "none", lg: "flex" }}
        >
            <HStack spacing="1" mx="auto">
                {NAV_LINKS.map((link) => {
                    return (
                        <Link href={link.href} passHref key={link.href}>
                            <Box
                                as="a"
                                textTransform="uppercase"
                                px="3"
                                py="2"
                                transition="all 0.3s"
                                rounded="md"
                                aria-selected={pathname === link.href}
                                _hover={{
                                    bg: "gray.100",
                                }}
                                _selected={{
                                    bg: "gray.100",
                                }}
                            >
                                {link.label}
                            </Box>
                        </Link>
                    );
                })}
                <FeedbackButton />
            </HStack>

            {user ? (
                <Menu>
                    <MenuButton>
                        <Box display="flex" alignItems="center">
                            <Avatar name={user?.full_name} size="sm" />{" "}
                            <Box
                                ml="1.5"
                                fontWeight="medium"
                                verticalAlign="middle"
                                color="gray.500"
                                display="flex"
                                alignItems="center"
                            >
                                <Box
                                    as="span"
                                    width="2px"
                                    height="20px"
                                    bg="gray.500"
                                    display="block"
                                    mr="1.5"
                                ></Box>{" "}
                                Profile
                            </Box>
                        </Box>
                    </MenuButton>

                    <MenuList>
                        <Link href="/landing-pages" passHref>
                            <MenuItem icon={<ProfileIcon />} as="a">
                                Dashboard
                            </MenuItem>
                        </Link>

                        <Link href="/profile" passHref>
                            <MenuItem icon={<ProfileSettings />} as="a">
                                Account Settings
                            </MenuItem>
                        </Link>

                        <MenuItem
                            icon={<LogoutIcon />}
                            onClick={() => auth.logout()}
                        >
                            Logout
                        </MenuItem>
                    </MenuList>
                </Menu>
            ) : (
                <HStack spacing="4">
                    <Link href="/login" passHref>
                        <Button colorScheme="brand" as="a" minW="150px">
                            Sign In
                        </Button>
                    </Link>

                    <Link href="/register" passHref>
                        <Button variant="outline" colorScheme="brand">
                            Get Started
                        </Button>
                    </Link>
                </HStack>
            )}
        </Flex>
    );
}

function MobileNavigation() {
    const { isOpen, onClose, onToggle } = useDisclosure();
    const ref = React.useRef<HTMLDivElement>(null);
    const { user } = useAuth();

    const links = user
        ? [
              ...NAV_LINKS,
              { label: "Dashboard", href: "/landing-pages" },
              { label: "Account Settings", href: "/profile" },
          ]
        : [...NAV_LINKS, ...AUTH_LINKS];

    useOutsideClick({
        ref: ref,
        handler: () => onClose(),
    });

    React.useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = "hidden";
            window.scrollTo(0, 0);
        } else {
            document.body.style.overflow = "auto";
        }

        return () => {
            document.body.style.overflow = "auto";
        };
    }, [isOpen]);

    React.useEffect(() => {
        const handler = () => {
            onClose();
        };

        window.addEventListener("resize", handler);

        return () => {
            window.removeEventListener("resize", handler);
        };
    }, []);

    return (
        <>
            {isOpen && (
                <Portal>
                    <Box
                        zIndex="100"
                        bg="blackAlpha.600"
                        width="100%"
                        height="100%"
                        position="fixed"
                        top="0"
                        left="0"
                    />
                </Portal>
            )}
            <Box as="nav" display={{ base: "block", lg: "none" }}>
                <IconButton
                    variant="unstyled"
                    fontSize="28px"
                    icon={<MenuIcon />}
                    aria-label="nav menu icon"
                    onClick={onToggle}
                    position="relative"
                    zIndex="101"
                />

                <Portal>
                    <Flex
                        as="nav"
                        direction="column"
                        position="fixed"
                        top="0"
                        insetInlineStart="0"
                        width="min(75%, 300px)"
                        height="100%"
                        bg="white"
                        transform={
                            isOpen ? "translateX(0px)" : "translateX(-101%)"
                        }
                        transition="all 0.3s"
                        zIndex="101"
                    >
                        <Box py="6" px="6" maxW="200px">
                            <Logo />
                        </Box>

                        <Box as="ul" listStyleType="none">
                            {links.map((link, index) => (
                                <Box
                                    as="li"
                                    m="0"
                                    key={index}
                                    borderBottom="1px"
                                    borderColor="inherit"
                                >
                                    <Link href={link.href} passHref>
                                        <Box
                                            as="a"
                                            href={"#" + link}
                                            display="block"
                                            textTransform="capitalize"
                                            py="5"
                                            px="10"
                                            _hover={{
                                                bg: "gray.50",
                                            }}
                                        >
                                            {link.label}
                                        </Box>
                                    </Link>
                                </Box>
                            ))}

                            {user && (
                                <Box
                                    as="li"
                                    m="0"
                                    borderBottom="1px"
                                    borderColor="inherit"
                                >
                                    <Box
                                        onClick={() => auth.logout()}
                                        display="block"
                                        textTransform="capitalize"
                                        py="5"
                                        px="10"
                                        _hover={{
                                            bg: "gray.50",
                                        }}
                                    >
                                        Logout
                                    </Box>
                                </Box>
                            )}
                        </Box>
                    </Flex>
                </Portal>
            </Box>
        </>
    );
}

function StandardLayoutHeader() {
    const [isHidden, setIsHidden] = React.useState(false);
    const prevPageYOffset = React.useRef(0);
    const { user } = useAuth();

    const MIN_OFFSET = 100;

    React.useEffect(() => {
        prevPageYOffset.current = window.pageYOffset;
    }, []);

    useEventListener("scroll", () => {
        if (
            window.pageYOffset > prevPageYOffset.current &&
            window.pageYOffset > MIN_OFFSET
        ) {
            setIsHidden(true);
        } else {
            setIsHidden(false);
        }
        prevPageYOffset.current = window.pageYOffset;
    });

    return (
        <Box
            as="header"
            position="fixed"
            top="0"
            left="0"
            right="0"
            color="gray.700"
            zIndex="9"
            transition="all 0.3s"
            willChange="transform"
            transform={isHidden ? "translateY(-100%)" : "translateY(0)"}
            bg="white"
            borderBottom="1px"
            borderColor="inherit"
        >
            {user ? (
                <Announcement
                    isOpened={isUserUsingFreePlan(user)}
                >
                    You're on a free plan.{" "}
                    <Link href="/pricing">
                        <Box as="a" textDecor="underline" cursor="pointer">
                            Upgrade Now
                        </Box>
                    </Link>{" "}
                    to use your Landing Page without any limitations
                </Announcement>
            ) : null}

            <MaxWidthWrapper
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                height={HEADER_HEIGHT + "px"}
            >
                <Logo maxW="120px" color="brand.500" />
                <DesktopNavigation />
                <MobileNavigation />
            </MaxWidthWrapper>
        </Box>
    );
}

export default StandardLayoutHeader;
