import client from "@lib/client";
import { useMutation } from "@tanstack/react-query";
import { Answer } from "../types";
import AppError from "@lib/error";
import { serialize } from "object-to-formdata";
import { getRecaptchaResult } from "@lib/utils";

interface MutationData {
    answers: Answer[];
}

export const answerForm = async ({ answers }: MutationData): Promise<void> => {
    const recaptchaResult = await getRecaptchaResult();
    const data = {
        recaptcha_response: recaptchaResult,
        answers
    };
    const formData = serialize(data);
    const res = await client.post(`/api/web/answers`, formData);
}

function useAnswerForm(){
    return useMutation<void, AppError, MutationData>(answerForm);
}

export default useAnswerForm;