import React from "react";
import {
    Divider,
    Stack,
    Text,
    Box,
    Grid,
    Heading,
    HStack,
} from "@chakra-ui/react";
import { SOCIAL_LINKS } from "@lib/constants";
import MaxWidthWrapper from "../MaxWidthWrapper";
import Logo from "@features/ui/Logo";
import Link from "next/link";
import { COMPANY_LINKS, HELP_LINKS, QUICK_LINKS } from "./Footer.constants";
import TrustPilot from "../TrustPilot";

function Footer() {
    return (
        <Box
            as="footer"
            bg="gray.50"
            color="gray.800"
            borderTop="1px"
            borderColor="inherit"
            overflow="hidden"
        >
            <MaxWidthWrapper>
                <Stack
                    spacing="8"
                    direction={{ base: "column", md: "row" }}
                    justify="space-between"
                    py={{ base: "12", md: "16" }}
                >
                    <Box mb="10">
                        <Logo
                            width="200px"
                            color="inherit"
                            display="block"
                            mb="6"
                        />
                        <Text maxW="500px" mb="4">
                            Create professional landing pages, increase
                            conversions and drive sales in minutes{" "}
                        </Text>
                        <Box>
                            <TrustPilot />
                        </Box>
                    </Box>

                    <Grid
                        templateColumns={{
                            base: "1fr 1fr",
                            sm: "1fr 1fr 1fr",
                        }}
                        gap={10}
                    >
                        <Box>
                            <Heading size="md" fontWeight="semibold" mb="4">
                                Quick Links
                            </Heading>

                            <Box>
                                {QUICK_LINKS.map((link) => {
                                    return (
                                        <Link
                                            href={link.href}
                                            key={link.href}
                                            passHref
                                        >
                                            <Box
                                                as="a"
                                                color="inherit"
                                                display="block"
                                                mb="2"
                                            >
                                                {link.label}
                                            </Box>
                                        </Link>
                                    );
                                })}
                            </Box>
                        </Box>
                        <Box>
                            <Heading size="md" fontWeight="semibold" mb="4">
                                Company
                            </Heading>

                            <Box>
                                {COMPANY_LINKS.map((link) => {
                                    return (
                                        <Link
                                            href={link.href}
                                            key={link.href}
                                            passHref
                                        >
                                            <Box
                                                as="a"
                                                color="inherit"
                                                display="block"
                                                mb="2"
                                            >
                                                {link.label}
                                            </Box>
                                        </Link>
                                    );
                                })}
                            </Box>
                        </Box>
                        <Box>
                            <Heading size="md" fontWeight="semibold" mb="4">
                                Help
                            </Heading>

                            <Box>
                                {HELP_LINKS.map((link) => {
                                    return (
                                        <Link
                                            href={link.href}
                                            key={link.href}
                                            passHref
                                        >
                                            <Box
                                                as="a"
                                                color="inherit"
                                                display="block"
                                                mb="2"
                                            >
                                                {link.label}
                                            </Box>
                                        </Link>
                                    );
                                })}
                            </Box>
                        </Box>
                    </Grid>
                </Stack>

                <Divider />

                <Stack
                    pt="8"
                    pb="12"
                    justify="space-between"
                    direction={{ base: "column-reverse", md: "row" }}
                    align="center"
                >
                    <Text fontSize="sm">
                        &copy; {new Date().getFullYear()} All rights reserved |
                        BITHUB
                    </Text>
                    <HStack>
                        {SOCIAL_LINKS.map((link) => {
                            return (
                                <Box
                                    as="a"
                                    aria-label="LinkedIn"
                                    fontSize="xl"
                                    target="_blank"
                                    href={link.href}
                                    key={link.href}
                                >
                                    {link.icon}
                                </Box>
                            );
                        })}
                    </HStack>
                </Stack>
            </MaxWidthWrapper>
        </Box>
    );
}
export default Footer;
