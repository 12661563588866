import client from "@lib/client";
import { useMutation } from "@tanstack/react-query";
import { answerForm } from "@features/forms/hooks/useAnswerForm";
import { Question } from "@features/forms/types";
import { randomCookieValue } from "@lib/utils";

export interface Feedback {
    email: string;
    feedback: string;
    emoji: string;
}

const sendFeedback = async (data: Feedback): Promise<void> => {
    const { data: feedbackQuestions } = await client.get<Question[]>("/api/web/forms/request_a_feature");
    const cookieValue = randomCookieValue();
    const answers = feedbackQuestions.map(question => {
        const value = data[question.phrase.toLowerCase() as keyof Feedback];
        return {
            question_id: question.id,
            phrase: value,
            cookie_value: cookieValue
        }
    });
    await answerForm({ answers })
} 

function useSendFeedback(){
    return useMutation({
        mutationFn: (data: Feedback) => sendFeedback(data)
    });
}

export default useSendFeedback;