import User, { SubscriptionMetrics } from "./types";

export function canUserCreateNewLandingPage(user: User) {
    const landingPagesMetrics =
        user.organisation?.organisation_subscription.subscription_metrics.find(
            (metric) => {
                return (
                    metric.subscription_product.product_type === "landing_page"
                );
            }
        ) as SubscriptionMetrics;

    if (!landingPagesMetrics.subscription_product.max_amount) return true;

    console.log(landingPagesMetrics);

    return (
        landingPagesMetrics.current_amount <
        landingPagesMetrics.subscription_product.max_amount
    );
}

export function canUserCreateNewForm(user: User) {
    const formMetrics =
        user.organisation?.organisation_subscription.subscription_metrics.find(
            (metric) => {
                return metric.subscription_product.product_type === "form";
            }
        ) as SubscriptionMetrics;

    if (!formMetrics.subscription_product.max_amount) return true;

    return (
        formMetrics.current_amount < formMetrics.subscription_product.max_amount
    );
}

export function hasUserReachedMaxLandingPageViews(user: User): boolean {
    const landingPageMetrics =
        user.organisation?.organisation_subscription.subscription_metrics.find(
            (metric) => {
                return (
                    metric.subscription_product.product_type === "landing_page"
                );
            }
        ) as SubscriptionMetrics;

    if (!landingPageMetrics.subscription_product.max_monthly_count)
        return false;

    return (
        landingPageMetrics.current_monthly_count >=
        landingPageMetrics.subscription_product.max_monthly_count
    );
}

export function hasUserReachedMaxFormsResponses(user: User): boolean {
    const formMetrics =
        user.organisation?.organisation_subscription.subscription_metrics.find(
            (metric) => {
                return metric.subscription_product.product_type === "form";
            }
        ) as SubscriptionMetrics;

    if (!formMetrics.subscription_product.max_monthly_count) return false;

    return (
        formMetrics.current_monthly_count >=
        formMetrics.subscription_product.max_monthly_count
    );
}

export function isUserSubscribedToPlan(user: User, planId: number): boolean {
    return user.organisation?.organisation_subscription?.plan_id === planId;
}

export function getLandingPagesMetrics(user: User) {
    const landingPageMetrics =
        user.organisation?.organisation_subscription.subscription_metrics.find(
            (metric) => {
                return (
                    metric.subscription_product.product_type === "landing_page"
                );
            }
        ) as SubscriptionMetrics;

        return {
            totalLandingPagesUserCanCreate: landingPageMetrics.subscription_product.max_amount,
            currentLandingPagesUserHasCreated: landingPageMetrics.current_amount,
            totalViewsUserCanReceive:
                landingPageMetrics.subscription_product.max_monthly_count,
            currentViewsUserHasReceived: landingPageMetrics.current_monthly_count,
        };
}

export function getFormsMetrics(user: User) {
    const formMetrics =
        user.organisation?.organisation_subscription.subscription_metrics.find(
            (metric) => {
                return metric.subscription_product.product_type === "form";
            }
        ) as SubscriptionMetrics;

    return {
        totalFormsUserCanCreate: formMetrics.subscription_product.max_amount,
        currentFormsUserHasCreated: formMetrics.current_amount,
        totalResponsesUserCanReceive:
            formMetrics.subscription_product.max_monthly_count,
        currentResponsesUserHasReceived: formMetrics.current_monthly_count,
    };
}

export function isUserUsingFreePlan(user: User): boolean {
    return user.organisation?.organisation_subscription?.plan_name.toLowerCase() ===  "early adopter";
}