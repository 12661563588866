import { Box, Flex } from "@chakra-ui/react";
import { SiTrustpilot as TrustPilotIcon } from "react-icons/si";

function TrustPilot() {
    return (
        <Flex
            align="center"
            as="a"
            target="_blank"
            href="https://www.trustpilot.com/review/the-bithub.com?utm_medium=trustbox&utm_source=MicroReviewCount"
        >
            We're on{" "}
            <Box as="span" color="green.600" mx="1">
                <TrustPilotIcon />
            </Box>{" "}
            <Box as="span" fontWeight="bold">
                TrustPilot
            </Box>
        </Flex>
    );
}

export default TrustPilot;
