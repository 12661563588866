import { Box, BoxProps } from "@chakra-ui/react";

function MaxWidthWrapper(props: BoxProps){
    return (
        <Box 
            px={{ base: 4, md: 6 }}
            mx="auto"
            maxW="1280"
            {...props}
        />
    )
}

export default MaxWidthWrapper;