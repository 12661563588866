
export const NAV_LINKS = [
    {
        label: "Home",
        href: "/"
    },
    {
        label: "About",
        href: "/about",
    },
    // {
    //     label: "Gallery",
    //     href: "/gallery"
    // },
    {
        label: "Pricing",
        href: "/pricing"
    },
    {
        label: "FAQs",
        href: "/faqs"
    },
    {
        label: "Blog",
        href: "/posts"
    }
];

export const AUTH_LINKS = [
    {
        label: "Sign In",
        href: "/login"
    },
    {
        label: "Get Started",
        href: "/register"
    }
];

export const HEADER_HEIGHT = 70;