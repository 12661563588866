import Color from "color";
import { v4 } from "uuid";

export function pick<T extends object>(
    obj: { [key in keyof T]: any },
    keys: (keyof T)[]
): T {
    const result = {} as T;
    for (let key of keys) {
        result[key] = obj[key];
    }
    return result;
}

export function getRecaptchaResult(): Promise<string> {
    return new Promise((resolve) => {
        if(typeof grecaptcha === "undefined") return resolve("");
        grecaptcha.ready(function () {
            grecaptcha
                .execute(process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY || "", {
                    action: "view",
                })
                .then(function (token) {
                    resolve(token);
                });
        });
    });
}

export function bindIFrameMousemove(iframe: HTMLIFrameElement) {
    iframe.contentWindow?.addEventListener("mousemove", function (event) {
        const clRect = iframe.getBoundingClientRect();
        const evt: any = new CustomEvent("mousemove", {
            bubbles: true,
            cancelable: false,
        });

        evt.clientX = event.clientX + clRect.left;
        evt.clientY = event.clientY + clRect.top;

        iframe.dispatchEvent(evt);
    });
}

export function swap(arr: any[], indexA: number, indexB: number) {
    const tmp = arr[indexB];
    arr[indexB] = arr[indexA];
    arr[indexA] = tmp;
}

export function numberWithCommas(n: number): string {
    return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function fallbackColor(
    color: string,
    fallback: string = "#ffffff"
): Color {
    let c;

    try {
        c = Color(color);
    } catch (err) {
        c = Color(fallback);
    }

    return c;
}

export function interpolate(
    xAxis: [number, number],
    yAxis: [number, number],
    value: number
): number {
    const [x1, x2] = xAxis;
    const [y1, y2] = yAxis;
    const m = (y2 - y1) / x2 - x1;
    return m * value;
}

export function guardNumber(num: any, fallback: number): number {
    num = Number(num);
    if (Number.isNaN(num)) return fallback;
    return num;
}

export function range(start: number, end: number): number[] {
    let length = end - start + 1;
    return Array.from({ length }, (_, idx) => idx + start);
}

function forceDownload(blob: string, filename: string) {
    var a = document.createElement("a");
    a.download = filename;
    a.href = blob;
    // For Firefox https://stackoverflow.com/a/32226068
    document.body.appendChild(a);
    a.click();
    a.remove();
}

// Current blob size limit is around 500MB for browsers
export function downloadResource(url: string, filename: string) {
    fetch(url, {
        headers: new Headers({
            Origin: location.origin,
        }),
        mode: "cors",
    })
        .then((response) => response.blob())
        .then((blob) => {
            let blobUrl = window.URL.createObjectURL(blob);
            forceDownload(blobUrl, filename);
        })
        .catch((e) => console.error(e));
}

export function isValidEmail(email: string): boolean {
    return !!String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
}

export function isValidSpotify(link: string): boolean {
    return !!String(link)
        .toLowerCase()
        .match(/^(spotify:|https:\/\/[a-z]+\.spotify\.com\/)/);
}

export function randomCookieValue(){
    return v4();
}

