import React from "react";
import { Box } from "@chakra-ui/react";
import Header from "./StandardLayoutHeader";
import { HEADER_HEIGHT } from "./StandardLayout.constants";
import FooterLayout from "../Footer/Footer"
interface StandardLayoutProps {
    children: React.ReactNode;
}

function StandardLayout(props: StandardLayoutProps){
    return (
        <Box>
            <Header />

            <Box as="main" pt={HEADER_HEIGHT + "px"}>
                {props.children}
            </Box>
            <FooterLayout/>
        </Box>
    )
}

export default StandardLayout;