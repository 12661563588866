import {
    Box,
    Button,
    Center,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    HStack,
    IconButton,
    Image,
    Input,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverFooter,
    PopoverTrigger,
    Portal,
    Textarea,
    useToast,
} from "@chakra-ui/react";
import { useAuth } from "@features/users/hooks";
import useSendFeedback, {
    Feedback,
} from "@features/users/hooks/useSendFeedback";
import React from "react";
import { useForm } from "react-hook-form";

import { BiSend as SendIcon } from "react-icons/bi";

const emojis = [
    {
        value: "😍",
        label: <Image src="/images/superstar.png" />,
    },
    {
        value: "😃",
        label: <Image src="/images/happy.png" />,
    },
    {
        value: "😕",
        label: <Image src="/images/sad.png" />,
    },
    {
        value: "😭",
        label: <Image src="/images/crying.png" />,
    },
];

function FeedbackButton() {
    const { isLoading, mutate } = useSendFeedback();
    const toast = useToast();
    const { user } = useAuth();

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm<Feedback>({
        defaultValues: {
            email: user ? user.email : undefined,
        },
    });

    const onSubmit = (values: Feedback) => {
        const data = {
            ...values,
            email: user ? user.email : values.email,
        };

        console.log("Submitting");

        mutate(data, {
            onSuccess: () => {
                toast({
                    title: "Thanks! We have received your feedback",
                    isClosable: true,
                    status: "success",
                });
                reset();
            },
            onError: () => {
                toast({
                    title: "Failed to send feedback!",
                    isClosable: true,
                    status: "error",
                });
            },
        });
    };

    return (
        <Popover>
            <PopoverTrigger>
                <Button colorScheme="brand" variant="ghost">Feedback</Button>
            </PopoverTrigger>

            <Portal>
                <PopoverContent boxShadow="xl">
                    <PopoverArrow />

                    {/* <PopoverCloseButton /> */}
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <PopoverBody>
                            {!user ? (
                                <FormControl mb="3" isInvalid={!!errors?.email}>
                                    <FormLabel>Email</FormLabel>
                                    <Input
                                        type="email"
                                        placeholder="Your email address..."
                                        {...register("email", {
                                            required: "Email is required!",
                                        })}
                                    />
                                    <FormErrorMessage>
                                        {errors?.email?.message}
                                    </FormErrorMessage>
                                </FormControl>
                            ) : null}

                            <FormControl mb="3" isInvalid={!!errors?.feedback}>
                                <FormLabel>Feedback</FormLabel>
                                <Textarea
                                    {...register("feedback", {
                                        required: "Feedback is required!",
                                    })}
                                    placeholder="Your feedback..."
                                />
                                <FormErrorMessage>
                                    {errors?.feedback?.message}
                                </FormErrorMessage>
                            </FormControl>
                        </PopoverBody>

                        <PopoverFooter>
                            <Flex justify="space-between" align="center">
                                <FormControl isInvalid={!!errors?.emoji}>
                                    <HStack spacing="3">
                                        {emojis.map((emoji) => (
                                            <Box
                                                key={emoji.value}
                                                sx={{
                                                    "input:checked + label": {
                                                        border: "2px",
                                                        borderColor: "brand.700"
                                                    },
                                                }}
                                            >
                                                <Input
                                                    type="radio"
                                                    value={emoji.value}
                                                    id={emoji.value}
                                                    hidden
                                                    {...register("emoji", {
                                                        required:
                                                            "Emoji is required!",
                                                    })}
                                                />
                                                <Center
                                                    cursor="pointer"
                                                    htmlFor={emoji.value}
                                                    as="label"
                                                    w="32px"
                                                    h="32px"
                                                    rounded="full"
                                                    bg="gray.100"
                                                    border="2px"
                                                    p="2px"
                                                    borderColor="transparent"
                                                    transition="all 0.3s"
                                                >
                                                    {emoji.label}
                                                </Center>
                                            </Box>
                                        ))}
                                    </HStack>
                                    <FormErrorMessage>
                                        {errors?.emoji?.message}
                                    </FormErrorMessage>
                                </FormControl>

                                <Box>
                                    <IconButton
                                        icon={<SendIcon />}
                                        colorScheme="brand"
                                        aria-label="feedback send icon"
                                        type="submit"
                                        isLoading={isLoading}
                                        size="sm"
                                    />
                                </Box>
                            </Flex>
                        </PopoverFooter>
                    </form>
                </PopoverContent>
            </Portal>
        </Popover>
    );
}

export default FeedbackButton;
