import Link from "next/link";
import { Box, BoxProps } from "@chakra-ui/react";

function Logo(props: BoxProps){
    return (
        <Link href="/" passHref>
            <Box 
                as="a"
                color="brand.500"
                {...props}
            >
                <svg width="455" style={{ maxWidth: "100%" }} viewBox="0 0 455 95" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_63_165)">
                    <path d="M188.33 61C189.497 60.2193 190.551 59.2803 191.46 58.21C193.46 55.8033 194.46 52.6067 194.46 48.62V47.25C194.46 41.5033 192.48 37.0733 188.52 33.96C184.56 30.8467 178.893 29.2933 171.52 29.3H147.42V94.5H174.08C178.405 94.6279 182.712 93.8936 186.75 92.34C189.911 91.1014 192.613 88.9191 194.49 86.09C196.311 83.1489 197.229 79.7379 197.13 76.28V75.05C197.13 70.95 196.13 67.6933 194.13 65.28C192.547 63.4307 190.564 61.9669 188.33 61ZM172.41 40.56C175.16 40.56 177.16 41.26 178.41 42.67C179.721 44.3126 180.38 46.3815 180.26 48.48C180.362 50.521 179.703 52.5272 178.41 54.11C177.18 55.52 175.18 56.22 172.41 56.22H161.5V40.56H172.41ZM180.85 81.12C179.51 82.53 177.48 83.24 174.78 83.24H161.5V67.13H174.78C177.48 67.13 179.51 67.82 180.85 69.2C181.559 70.0091 182.101 70.9506 182.444 71.9701C182.787 72.9895 182.925 74.0669 182.85 75.14C182.998 77.3191 182.279 79.4685 180.85 81.12V81.12Z" fill="currentColor"/>
                    <path d="M219.11 29.3H204.46V94.5H219.11V29.3Z" fill="currentColor"/>
                    <path d="M226.43 42.07H244.74V94.5H259.39V42.07H277.79V29.3H226.43V42.07Z" fill="currentColor"/>
                    <path d="M320.18 55.29H299.76V29.3H285.11V94.5H299.76V68.15H320.18V94.5H334.83V29.3H320.18V55.29Z" fill="currentColor"/>
                    <path d="M383.59 67.51C383.651 70.0387 383.106 72.5452 382 74.82C380.995 76.8181 379.399 78.4589 377.43 79.52C375.135 80.6963 372.578 81.2677 370 81.18C367.445 81.2628 364.912 80.6914 362.64 79.52C360.649 78.4468 359.031 76.7937 358 74.78C356.878 72.5243 356.326 70.0284 356.39 67.51V29.3H342.15V67C342.021 72.0625 343.126 77.0803 345.37 81.62C347.468 85.6931 350.767 89.0233 354.82 91.16C358.94 93.38 364 94.4933 370 94.5C376 94.5067 381.077 93.39 385.23 91.15C389.263 89.0025 392.542 85.6738 394.63 81.61C396.876 77.0742 397.985 72.0599 397.86 67V29.3H383.59V67.51Z" fill="currentColor"/>
                    <path d="M451.91 65.28C450.325 63.4295 448.337 61.9656 446.1 61C447.267 60.2193 448.321 59.2803 449.23 58.21C451.23 55.8033 452.23 52.6067 452.23 48.62V47.25C452.23 41.5033 450.25 37.0733 446.29 33.96C442.33 30.8467 436.663 29.2933 429.29 29.3H405.19V94.5H431.85C436.175 94.6279 440.482 93.8936 444.52 92.34C447.681 91.1014 450.383 88.9191 452.26 86.09C454.081 83.1489 454.999 79.7379 454.9 76.28V75.05C454.9 70.95 453.903 67.6933 451.91 65.28ZM430.18 40.56C432.93 40.56 434.93 41.26 436.18 42.67C437.481 44.3168 438.129 46.3854 438 48.48C438.102 50.521 437.443 52.5272 436.15 54.11C434.92 55.52 432.92 56.22 430.15 56.22H419.27V40.56H430.18ZM438.62 81.12C437.28 82.53 435.25 83.24 432.55 83.24H419.27V67.13H432.55C435.25 67.13 437.28 67.82 438.62 69.2C439.329 70.0091 439.871 70.9506 440.214 71.9701C440.557 72.9895 440.695 74.0669 440.62 75.14C440.768 77.3191 440.049 79.4685 438.62 81.12V81.12Z" fill="currentColor"/>
                    <path d="M70.87 1.05875e-06C77.0753 -0.00131266 83.2201 1.21995 88.9533 3.59402C94.6866 5.96809 99.8959 9.44845 104.284 13.8363C108.672 18.2241 112.152 23.4334 114.526 29.1667C116.9 34.8999 118.121 41.0447 118.12 47.25V94.5H70.87C83.3994 81.9674 90.4381 64.9715 90.4381 47.25C90.4381 29.5285 83.3994 12.5326 70.87 1.05875e-06Z" fill="currentColor"/>
                    <path d="M47.25 1.05875e-06C41.0447 -0.00131266 34.8999 1.21995 29.1667 3.59402C23.4334 5.96809 18.2241 9.44845 13.8363 13.8363C9.44845 18.2241 5.96809 23.4334 3.59402 29.1667C1.21995 34.8999 -0.00131266 41.0447 1.05875e-06 47.25V94.5H47.25C34.7206 81.9674 27.6819 64.9715 27.6819 47.25C27.6819 29.5285 34.7206 12.5326 47.25 1.05875e-06V1.05875e-06Z" fill="currentColor"/>
                    <path d="M59.06 29.3C63.3427 33.5802 66.74 38.6623 69.058 44.256C71.3759 49.8496 72.5689 55.8451 72.5689 61.9C72.5689 67.9549 71.3759 73.9504 69.058 79.5441C66.74 85.1377 63.3427 90.2198 59.06 94.5V94.5C50.4156 85.8531 45.5594 74.1268 45.5594 61.9C45.5594 49.6732 50.4156 37.9469 59.06 29.3V29.3Z" fill="currentColor"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_63_165">
                    <rect width="454.9" height="94.5" fill="white"/>
                    </clipPath>
                    </defs>
                </svg>
            </Box>
        </Link>
    )
}

export default Logo;